<template>
  <div class="case_page">
    <div class="banner_wrap">
      <p class="title fadeInLeft slow animated wow">携手全国客户，共助建筑数字化未来</p>
      <p class="title_m fadeInLeft slow animated wow">携手全国客户</p>
      <p class="title_m fadeInLeft slow animated wow">共助建筑数字化未来</p>
      <p class="des fadeInRight slow animated wow">
        足迹遍布全国32个省级行政区服务，客户超过200个，致力让每一个建筑企业都用上UNRE的产品和服务
      </p>
    </div>

    <div class="des_wrap">
      <div class="des_content">
        <div class="item" v-for="(item, index) in list" :key="index">
          <img class="left fadeInLeft slow animated wow" :src="item.img" alt="" />
          <div class="right fadeInRight slow animated wow">
            <div class="title">{{ item.name }}</div>
            <p class="name">{{ item.title }}</p>
            <p class="des">
              {{ item.des }}
            </p>
            <div class="btn" @click="showMore(item.id)">了解更多</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from "wowjs"
import list from "@/utils/case";
export default {
  name: "Case",
  data() {
    return {
      list: [],
    };
  },
  components: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });
    this.list = list;
  },
  methods: {
    showMore(id) {
      this.$router.push({
        path: "/Info",
        query: { ca: id },
      });
    },
  },
};
</script>

<style lang="less">
.case_page {
  background: #edf1f7;
  overflow: hidden;
  .breadcrumb_wrap {
    margin-bottom: 0.32rem;
  }
  .banner_wrap {
    background-image: url(../assets/case_bg.png);
    background-size: cover;
    width: 10rem;
    height: 2.88rem;
    padding-top: 0.96rem;
    .title {
      display: block;
      margin-bottom: 0.1rem;
      color: #fcfcfc;
      font-size: 0.21rem;
    }
    .title_m {
      display: none;
    }
    .des {
      color: #e6e6e6;
      font-size: 0.13rem;
    }
  }

  .des_wrap {
    padding: 0 1.05rem 0.32rem;

    .des_content {
      width: 100%;
      .item {
        padding: 0.19rem 0.34rem;
        background-color: #fff;

        display: flex;
        justify-content: space-between;
        margin-bottom: 0.29rem;
        &:last-child {
          margin-bottom: 0;
        }
        &:nth-child(even) {
          flex-direction: row-reverse;
        }
      }
      .left {
        width: 3.46rem;
      }
      .right {
        width: 3.4rem;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          border-left: 0.02rem solid #02aeec;
          height: 0.17rem;
          margin-bottom: 0.15rem;
          margin-top: 0.25rem;
          line-height: 0.17rem;
          padding-left: 0.06rem;
          color: #02aeec;
          font-weight: 600;
          font-size: 0.15rem;
        }
        margin-bottom: 0.09rem;
        color: #4d4d4d;
        font-weight: 600;
        font-size: 0.1rem;
        .des {
          line-height: 0.16rem;
          color: #666666;
          font-size: 0.084rem;
          margin-bottom: 0.13rem;
          font-weight: 500;
        }
        .name {
          margin-bottom: 0.09rem;
          color: #4d4d4d;
          font-weight: 600;
          font-size: 0.1rem;
        }
        .btn {
          width: 0.8rem;
          height: 0.26rem;
          background: #02aeec;
          border-radius: 0.02rem 0.02rem 0.02rem 0.02rem;
          text-align: center;
          cursor: pointer;
          color: #fcfcfc;
          line-height: 0.26rem;
          font-size: 0.08rem;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .case_page {
    .breadcrumb_wrap {
      display: none;
    }
    .banner_wrap {
      background-image: url(../assets/case_bg_m.png);
      background-size: cover;
      width: 10rem;
      height: 16.79rem;
      padding-top: 6.31rem;
      .title {
        display: none;
      }
      .title_m {
        display: block;
        margin-bottom: 0.28rem;
        color: #fcfcfc;
        font-size: 0.43rem;
        &:last-child {
          margin-bottom: 0.38rem;
        }
      }
      .des {
        color: #e6e6e6;
        width: 9rem;
        font-size: 0.32rem;
        text-align: center;
        margin: 0 auto;
        
      }
    }

    .des_wrap {
      padding: 0.61rem 0.43rem;
      background: #fff;
      .des_content {
        width: 100%;
        .item {
          padding: 0.55rem 0.34rem;
          background: #edf1f7;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.29rem;
        }
        .left {
          width: 100%;
          margin-bottom: 0.43rem;
        }
        .right {
          width: 100%;
          text-align: left;
          .btn {
            width: 2.03rem;

            font-size: 0.29rem;
            height: 0.71rem;
            background: #02aeec;
            border-radius: 0.04rem 0.04rem 0.04rem 0.04rem;
            line-height: 0.71rem;
            color: #fff;
          }
          .title {
            border-left: 0.07rem solid #02aeec;
            height: 0.63rem;
            margin-bottom: 0.4rem;
            line-height: 0.63rem;
            padding-left: 0.06rem;
            color: #02aeec;
            font-weight: 600;

            font-size: 0.43rem;
          }
          .name {
            margin-bottom: 0.24rem;
            color: #4d4d4d;
            font-weight: 600;
            font-size: 0.37rem;
          }
          .des {
            line-height: 0.48rem;
            color: #666666;
            margin-bottom: 0.43rem;
            font-size: 0.32rem;
          }
        }
      }
    }
  }
}
</style>
